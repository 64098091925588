import {
    Clock,
    HemisphereLight,
    ShaderMaterial,
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    DirectionalLight,
    PointLight,
    IcosahedronGeometry,
    MeshPhysicalMaterial,
    TextureLoader,
    Vector2,
    RepeatWrapping
} from "three/src/Three.js";
import {RGBELoader} from "./RGBELoader.js";
import {HalfFloatType, Mesh, PMREMGenerator} from "three";

document.addEventListener("DOMContentLoaded", function () {
    if ($('.webgl2').length) {
        const scene = new Scene()
        let isAnim = true;
        let renderWidth = window.innerWidth
        let renderHeight = window.innerHeight

        let renderAspectRatio = renderWidth / renderHeight

        const camera = new PerspectiveCamera(45, renderAspectRatio, 0.1, 100)
        camera.position.z = 2
        camera.position.set(1.5, 1.5, 1.5);
        if (window.innerWidth <= 1000) {
            camera.position.set(3, 3, 3);
        }
        camera.lookAt(0, 0, 0);

        const canvas = document.querySelector('.webgl2');
        const renderer = new WebGLRenderer({antialias: true, canvas: canvas})
        renderer.setSize(renderWidth, renderHeight)
        renderer.setPixelRatio(window.devicePixelRatio * 1.5)


        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        renderer.autoClear = false;
        renderer.setClearColor(0x000000, 0.0);
        renderer.sortObjects = false;


        window.addEventListener(
            'load resize',
            () => {
                renderWidth = window.innerWidth
                renderHeight = window.innerHeight
                renderAspectRatio = renderWidth / renderHeight

                renderer.setPixelRatio(window.devicePixelRatio * 1.5)

                camera.aspect = renderAspectRatio
                camera.updateProjectionMatrix()

                renderer.setSize(renderWidth, renderHeight)
            },
            false
        )
        const dirLight = new DirectionalLight('#ffffff', 0.5)
        dirLight.position.set(15, 15, 15)
        const dirLight2 = new DirectionalLight('#9675da', 0.5)
        dirLight2.position.set(-200, -200, -10)
        const dirLight3 = new DirectionalLight('#ffffff', 0.5)
        dirLight3.position.set(600, 100, 20)
        const dirLight4 = new DirectionalLight('#ffffff', 0.5)
        dirLight4.position.set(30, 60, 20)
        const pointsLight = new PointLight('#ffffff', 0.5)
        pointsLight.position.set(100, 100, 100)
        const pointsLight4 = new PointLight('#ffffff', 0.5)
        pointsLight4.position.set(-100, 100, -200)
        const pointsLight3 = new PointLight('#ffffff', 1)
        pointsLight3.position.set(200, -200, -200)
        const pointsLight5 = new PointLight('#ffffff', 0.5)
        pointsLight5.position.set(0, -350, 50)
        const light = new HemisphereLight(0xf834aa, 0x2a5cb9, 1);
        scene.add(light, dirLight, dirLight2, dirLight3, dirLight4, pointsLight, pointsLight3, pointsLight4, pointsLight5)


        const geometry = new IcosahedronGeometry(1, 220)
        const material = new MeshPhysicalMaterial({
            ...ShaderMaterial.uniforms,
            metalness: 1.0,
            clearcoat: 1.0,
            clearcoatRoughness: 0.1,
            fog: false,
            flatShading: true,
            roughness: 0.5,
            normalScale: new Vector2(0.15, 0.15),
            map: new TextureLoader().load("../img/texture.webp", tex => {
                tex.wrapS = RepeatWrapping;
                tex.wrapT = RepeatWrapping;
                tex.repeat.set(0, -1);
            }),


            userData: {
                shader: {
                    uniforms: {
                        uTime: {value: 0},
                    }
                }
            },

            onBeforeCompile: (shader) => {
                // storing a reference to the shader object
                material.userData.shader = shader

                // uniforms
                shader.uniforms.uTime = {value: 0}
                const parsVertexString = /* glsl */ `#include <displacementmap_pars_vertex>`
                shader.vertexShader = shader.vertexShader.replace(
                    parsVertexString,
                    parsVertexString + `
uniform float uTime;
varying float vDisplacement;

vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

float noise(vec3 P){
  vec3 Pi0 = floor(P); // Integer part for indexing
  vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
  Pi0 = mod(Pi0, 289.0);
  Pi1 = mod(Pi1, 289.0);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 / 7.0;
  vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 / 7.0;
  vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
  return 2.2 * n_xyz;
}


float smoothMod(float axis, float amp, float rad){
    float top = cos(PI * (axis / amp)) * sin(PI * (axis / amp));
    float bottom = pow(sin(PI * (axis / amp)), 2.0) + pow(rad, 3.0);
    float at = atan(top / bottom);
    return amp * (1.0 / 2.0) - (1.0 / PI) * at* at;
}

float fit(float unscaled, float originalMin, float originalMax, float minAllowed, float maxAllowed) {
  return (maxAllowed - minAllowed) * (unscaled - originalMin) / (originalMax - originalMin) + minAllowed;
}

float wave(vec3 position) {
  return fit(smoothMod(position.x * 5.0, 1.0, 1.5), 0.55, 0.6, 0.2, 1.0);
}

`
                )

                const mainVertexString = /* glsl */ `#include <displacementmap_vertex>`
                shader.vertexShader = shader.vertexShader.replace(
                    mainVertexString,
                    mainVertexString + `
vec3 coords = normal;
coords.y += uTime;

vec3 noisePattern = vec3(noise(coords / 3.5));
float pattern = wave(noisePattern + uTime);

// varyings
vDisplacement = pattern;


float displacement = vDisplacement / 2.5;

transformed += normalize(objectNormal) * displacement;
`
                )

                const mainFragmentString = /* glsl */ `#include <normal_fragment_maps>`
                const parsFragmentString = /* glsl */ `#include <bumpmap_pars_fragment>`
                shader.fragmentShader = shader.fragmentShader.replace(
                    parsFragmentString,
                    parsFragmentString + `
uniform float uTime;
varying float vDisplacement;

vec3 perturbNormalArb( vec3 surf_pos, vec3 surf_norm, vec2 dHdxy, float faceDirection ) {

    vec3 vSigmaX = dFdx( surf_pos.xyz );
    vec3 vSigmaY = dFdy( surf_pos.xyz );
    vec3 vN = surf_norm; // normalized

    vec3 R1 = cross( vSigmaY, vN );
    vec3 R2 = cross( vN, vSigmaX );

    float fDet = dot( vSigmaX, R1 ) * faceDirection;

    vec3 vGrad = sign( fDet ) * ( dHdxy.x * R1 + dHdxy.y * R2 );
    return normalize( abs( fDet ) * surf_norm - vGrad );

}

`
                )
                shader.fragmentShader = shader.fragmentShader.replace(
                    mainFragmentString,
                    mainFragmentString + `
    normal = perturbNormalArb( - vViewPosition, normal, vec2(dFdx(vDisplacement), dFdy(vDisplacement)), faceDirection );`,
                )
            },

        })

        const sphere = new Mesh(geometry, material)
        scene.add(sphere)

        const clock = new Clock()

        function setLighting() {

            const pmremGenerator = new PMREMGenerator(renderer);
            new RGBELoader()
                .setDataType(HalfFloatType)
                .setPath('../img/')
                .load('sunflowers_puresky_2k.hdr', function (texture) {

                    scene.environment = pmremGenerator.fromEquirectangular(texture).texture;

                    texture.dispose();
                    pmremGenerator.dispose();
                })
            pmremGenerator.compileEquirectangularShader();

        }

        setLighting()

        function animate() {
            if (!isAnim) return;
            const elapsedTime = clock.getElapsedTime();
            material.userData.shader.uniforms.uTime.value = elapsedTime * 0.05
            sphere.rotation.y = 0.01 * elapsedTime
            sphere.rotation.x = 0.01 * elapsedTime
            sphere.rotation.z = -0.01 * elapsedTime
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        }
        const iObserverSphere = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    isAnim = true;
                    animate()
                } else {
                    isAnim = false;
                }
            });
        });
        const box = document.querySelectorAll(".banner");
        box.forEach((el) => {
            iObserverSphere.observe(el);
        });
    }
})

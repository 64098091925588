import {animation, data, getGradient, positions, props1} from "./chart-functions.js";
import Chart from 'chart.js/auto';
import ChartDeferred from 'chartjs-plugin-deferred';


Chart.register(ChartDeferred);
Chart.defaults.set('plugins.deferred', {})
document.addEventListener('DOMContentLoaded', function () {
    function drawlineChart( ctx) {
        if (ctx) {
            const colorGradient = getGradient(ctx);
            const text1 = '+25.6%';
            const counterDiff = {
                id: 'counterDiff',
                beforeDraw(chart, arg, options) {
                    const {ctx, chartArea: {top, right, bottom, left, width, height}} = chart;
                    ctx.save();
                    ctx.fillStyle = options.fontcolor;
                    ctx.strokeStyle = options.fontcolor;
                    ctx.font = options.fontsize + "px" + " " + options.fontFamily;
                    ctx.textAlign = 'center';
                    ctx.fillText(` ${text1}`, width, top - 75)
                }
            }

            const config = {
                type: 'line',
                data: {
                    labels:{
                        value:['', 'Пн', '12', 'Вт', '12', 'Ср', '12', 'Чт'],
                    } ,
                    datasets: [{
                        label: 'Посещаемость',
                        data: data,

                        backgroundColor: () => {
                            if (colorGradient) {
                                return colorGradient.backgradient
                            }
                        },
                        borderColor: () => {
                            if (colorGradient) {
                                return colorGradient.gradient
                            }
                        },
                        borderWidth: 3,
                        pointHoverBorderColor: '#FFFFFF',
                        pointBorderColor: '#FFFFFF',
                        pointHoverBackgroundColor: '#403CE9',
                        pointBackgroundColor: () => {
                            if (colorGradient) {
                                return colorGradient.gradient
                            }
                        },
                        pointBorderWidth: 4,
                        pointHoverBorderWidth: 4,
                        pointShadow: ' 0px 2px 8px rgba(0, 0, 0, 0.19)',
                        pointRadius: 10,
                        pointHoverRadius: 12,
                        fill: true
                    }]
                },
                options: {
                 responsive:true,
                    maintainAspectRatio: false,
                    animation: animation(props1),
                    interaction: {
                        intersect: false
                    },

                    tension: 0.5,
                    plugins: {

                        title: {
                            display: true,
                            text: 'Посещаемость',
                            color: '#111',
                            font: {
                                weight: 700,
                                size: 19,
                                family: 'Inter',
                            },
                            position: 'top',
                            align: 'start',
                            padding: {
                                top: 20,
                                bottom: 70,
                            },
                        },
                        legend: {
                            display: false
                        },
                        counterDiff: {
                            fontcolor: '#39BF9F',
                            fontsize: 22,
                            fontFamily: 'Inter'
                        },
                        deferred: {
                            yOffset: '80%',
                        },

                    },

                    scales: {

                        y: {
                            beginAtZero: true,
                            grace: 1,
                            stacked: true,
                            ticks: {
                                stepSize: 150,
                                color:'#9DA9B1',
                                font: {
                                    weight: 500,
                                    size: 14,
                                    family: 'Inter',
                                },
                            }
                        },
                        x: {
                            type: 'linear',
                            stacked: true,

                            ticks: {
                                color:'#9DA9B1',
                                font: {
                                    weight: 500,
                                    size: 12,
                                    family: 'Inter',
                                },
                                callback: function (val, index) {
                                    // Hide every 2nd tick label
                                    const labels = this.getLabels(val)
                                    return index % 2 !== 0 ? labels.value[index] : '';
                                },
                            },
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                },
                plugins: [counterDiff],
            }
            const lineChart = new Chart(ctx,
                config
            );


            for (let i = 0; i < positions.length - 1; i++) {
                lineChart.hide(0, i)
            }
        }
    }
    const chartContainer = document.querySelector('.graph__block');
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                drawlineChart( document.getElementById('lineChart'))
                observer.unobserve(chartContainer);
            }
        });
    }, { threshold: 0 });

    observer.observe(chartContainer);
});




$('img.img-svg').each(function () {
    const $img = $(this);
    const imgClass = $img.attr('class');
    const imgURL = $img.attr('src');
    $.get(imgURL, function (data) {
        let $svg = $(data).find('svg');
        if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }
        $svg = $svg.removeAttr('xmlns:a');
        if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
        }
        $img.replaceWith($svg);
    }, 'xml');
});

const keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

let supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassive = true;
        }
    }));
} catch (e) {
}

const wheelOpt = supportsPassive ? {passive: false} : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

function getCurentFileName() {
    let pagePathName = window.location.pathname;
    return pagePathName.substring(pagePathName.lastIndexOf("/") + 1);
}

$(document).ready(function () {
    if($('.banner').length){
        setTimeout(()=>{
            $('.preloader').fadeOut().css("display", "none");
        },3000)
    } else{
         $('.preloader').fadeOut().css("display", "none");
    }

    const anchor = $('.footer__anchor')
    $(function () {
        $(document).on('scroll',{passive:true},(function () {
            if (($(this).scrollTop() > 300) && !anchor.hasClass('active')) {
                anchor.addClass('active')
            } else if ($(this).scrollTop() <= 300) {
                anchor.removeClass('active')
            }
        }));
    });
    let scroll_height = 300; // проскроленное растояние от начала страницы
    $(window).on('scroll',{passive:true},(function () {

        if ($(window).scrollTop() >= scroll_height) {
            anchor.fadeIn(300);
        } else {
            anchor.fadeOut(300);
        }
    }));

    anchor.on('hover',(function () {

        $(this).find('span').stop(true, true).animate({'opacity': 1}, 300);

    }, function () {

        $(this).find('span').stop(true, true).animate({'opacity': 0.8}, 300);

    }));

    anchor.on('click',(function () {

        $('body,html').animate({scrollTop: 0}, 400);

        return false;
    }));

    const navbar = $('.nav-container')
    const hamburger = $('.hamburger-lines')
    const closeMenuButton = $('.menu-items__close')
    hamburger.on('click', e => {
        e.preventDefault()
        navbar.addClass('open')
        disableScroll()
    })

    function closeMenu() {
        navbar.removeClass('open')
        enableScroll()
    }

    closeMenuButton.on('click', e => {
        e.preventDefault()
        closeMenu()
    })

    $('.header__button').on('click', function () {
        closeMenu()
    })
    $('.content').on('click', function (e) {
        closeMenu()
    })

    if ($('img').length) {
        $(function () {
            $("img.lazy").lazyload({effect: "fadeIn", placeholder:"../img/fon.webp"});
        });
    }

    if ($('.business').length) {
        AOS.init({
                once: true,
            }
        );
    }

    $('.block-info').on('click', '.item', function (e) {
        let item = $(e.currentTarget)
        item.toggleClass('active');
        $('.item').not(e.currentTarget).removeClass('active')
    })

    $(document).on('click', function (e) {
        let el = $('.item');
        if (!$(e.target).closest(el).length) {
            el.removeClass('active');
        }
    })


    $(document).on('mousemove', function (e) {
        $('.cursorHighlight')
            .css({
                top: e.clientY,
                left: e.clientX
            })
    });

    $('.pagination ').on('click', '.page', function (e) {
        $('.pagination__block').removeClass('active')
        $(e.currentTarget).addClass('active')
    })

    $('.pagination').on('click', '.pagination__block:first-child', function () {
        if ($('.pagination .page:not(:first-child)')) {
            let currentPage = $('.pagination .page.active')
            currentPage.prev().addClass('active')
            currentPage.removeClass('active')
        }
    })

    $('.pagination').on('click', '.pagination__block:last-child', function () {
        if ($('.pagination .page:not(:last-child)')) {
            let currentPage = $('.pagination .page.active')
            currentPage.next().addClass('active')
            currentPage.removeClass('active')
        }
    })

    $(".tabs-control a:first").addClass("active");

    $('.card__doughnut').on('click', '.card__button', function (e) {
        $('.card__doughnut').toggleClass('active')
    })

    if ($('.review-slider').length) {
        $('.review-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: true,
            dots: false,
            nextArrow: '.review-slider__arrow-right',
            prevArrow: '.review-slider__arrow-left',
        });
    }

    if ($('.form').length) {
        let $elem = $('.counter');
        let $form = $('.form')
        let windowHeight = $(window).height();
        let elemTop = $elem?.offset()?.top - windowHeight;
        let formTop = $form?.offset().top - windowHeight;

        let counted = false;
        $(document).on("scroll", function () {
            let scrollY = $(this).scrollTop();
            if ($('.counter').length) {
                setTimeout(() => {
                    if (scrollY > elemTop && !counted) {
                        $(document).off("scroll");
                        counted = true;
                        $('.counter__num').each(function () {
                            $(this).prop('Counter', 0).animate({
                                Counter: $(this).text()
                            }, {
                                duration: 2000,
                                easing: 'swing',
                                step: function (now) {
                                    $(this).text(Math.ceil(now));
                                }
                            });
                        });
                    }
                }, 200)
            }
        });
        $(window).on("scroll", function () {
            if (window.pageYOffset > formTop) {
                $('.form__waves').addClass('animated');
            } else {
                $('.form__waves').removeClass('animated')
            }
        })
    }

    if ($('select').length) {
        $('select').niceSelect()
    }

    $(".upload").on("change", function (e) {
        let files = $(this)[0].files;
        if (files.length >= 2) {
            $(".fileUpload span").text(files.length + " файла готовы к загрузке");
        } else if (files.length === 1) {
            let fileName = e.target.value.split("\\").pop().substring(0, 25);
            $(".fileUpload span").text(fileName);
        }
        $('.fileUpload__del').css('display','block');
        $('.fileUpload').css('padding-right','20px')
    });

    $('.fileUpload__del').on('click', function () {
        $(".upload").val('');
        $('.fileUpload__del').css('display', 'none');
        $('.fileUpload span').text('Прикрепить файл');
        $('.fileUpload').css('padding-right', '0');
    });

    function handlerMouseEnter(hover) {
        $('html').removeClass('--lightTheme')
        $('.webgl').removeClass('--lightColor')
        $('.webgl').removeClass('--originalColor')
        $('.webgl').addClass('--lightColor')
        $('html').addClass('--lightTheme')
        hover.play()
    }

    function handlerMouseOut(hover) {
        $('.webgl').removeClass('--lightColor')
        $('.webgl').addClass('--originalColor')
        $('html').removeClass('--lightTheme')
        hover.reverse()
    }

    // if ($('.webgl').length && window.innerWidth > 768) {
    //     const title = $('.banner__title span:last-child')
    //
    //     gsap.from($('.webgl'), {
    //         opacity: 0,
    //         background: 'linear-gradient(#29268f 9%, #1a1954 90%)',
    //     });
    //
    //     let hover = gsap.to($('.webgl'), {
    //         scale: 1.12,
    //         background: "linear-gradient(#91bff1 9%, #EFF8FF 90%)",
    //         duration: 1.5,
    //         paused: true,
    //         ease: "power1.inOut"
    //     });
    //     setTimeout(() => {
    //         title.on("mouseenter",()=> handlerMouseEnter(hover));
    //         title.on("mouseleave", () => handlerMouseOut(hover));
    //     }, 2000)
    // }

    const footer_menu = $('.footer__nav .menu');
    footer_menu.on('click', function () {
        $(this).toggleClass('active')
    })

    const mobile_menu_item = $('.navbar__menu-parentItem');
    mobile_menu_item.on('click', function () {
        $(this).toggleClass('active')
    })

    function slowScroll(id) {
        let offset = 80;
        if (id.length) {
            $('html').animate({
                scrollTop: $(id).offset().top - offset
            }, 1000);
            return false;
        }
    }

    $('.banner__button').on('click',function (e){
        e.preventDefault()
        slowScroll('.graph__block');
    });

    $('.filter__item-select').on('click', function (e) {
        let item = $(e.currentTarget)
        item.toggleClass('active');
        $('.filter__item-select').not(e.currentTarget).removeClass('active')
    })

     // реализовано на бэке
    // $('.tags__item').on('click', function () {
    //     $(this).toggleClass('active')
    //
    //     if($(this).hasClass('active')){
    //         addNewTag(this)
    //     }
    // })
    //
    //
    // $(document).on('click', ".current-tags__item span", function () {
    //     let dataId = $(this).closest('.current-tags__item').data('id');
    //     $(`.current-tags__item[data-id='${dataId}']`).remove();
    //     $(`.clone-tags .current-tags__item[data-id='${dataId}']`).remove();
    // });


    if ($('.case-slider__wrapper').length) {
        $('.case-slider__wrapper').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            nextArrow: '.case-slider__arrow-right',
            prevArrow: '.case-slider__arrow-left',
        });
    }

    if ($('.services-slider__wrapper').length) {
        $('.services-slider__wrapper').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            dots: false,
            nextArrow: '.services-slider__arrow-right',
            prevArrow: '.services-slider__arrow-left',
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        centerPadding: '90px'
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ],
        });
    }


    function getCurrentHeight(){
        return $('.current-tags__container')[0].clientHeight
    }

    $('.mobile-filter__button').on('click', function () {
        $('.content ').addClass('mobile')
        $('body ').addClass('mobile')
        $(".current-tags__container .current-tags .current-tags__item").clone().appendTo(".clone-tags");
    })

    $('.filter__close').on('click', function () {
        $('.content ').removeClass('mobile')
        $('body ').removeClass('mobile')
        $(".clone-tags .current-tags__item").remove()
    })

    $('input[type="tel"]').inputmask('+7(999)999-99-99');
    jQuery.validator.addMethod("phone", function (phone_number, element) {
        return phone_number.match(/^\+7[-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/);
    }, 'Проверьте номер'['phone']);

    $('#form').validate({
        rules: {
            name: {
                required: true,
            },
            phone: {
                required: true,
                phone: true
            },
        },
        messages: {
            name: {
                required: 'Заполните обязательное поле',
            },
            phone: {
                required: 'Заполните обязательное поле',
                phone: 'Проверьте номер'
            },
        }
    });

    function addNewTag(currentTag) {
        const wrapperTags = $('.current-tags');
        const currentSelectName = $('.filter__item-select.active')[0].innerHTML;
        wrapperTags.append(
            `
              <div class="current-tags__item"><span class="current-tags__item-title">${currentSelectName}:</span> ${currentTag.textContent}<span>&#215;</span></div>
            `
        )
    }

    let header = $('.header');
    let scrollThreshold = 60;

    $(window).on('scroll', { passive: true }, function () {
        let isHeaderFixed = header.hasClass('header--fixed');
        let scrollTop = $(this).scrollTop();

        if (scrollTop > scrollThreshold && !isHeaderFixed) {
            header.fadeIn(400).addClass('header--fixed');

        } else if (scrollTop <= scrollThreshold && isHeaderFixed) {
            header.fadeIn(400).removeClass('header--fixed');
            header.stop(true, true).animate({ opacity: (scrollTop > scrollThreshold) ? 0 : 1 }, 400);
        }
    });

});

// $(window).on('scroll', {passive: true}, (function () {
//     if (($(this).scrollTop() > 60) && !$('.header').hasClass('header--fixed')) {
//         $('.header').css('opacity', '0')
//         $('.header').addClass('header--fixed')
//         setTimeout(() => {
//             $('.header').css('opacity', '1').fadeIn(200)
//         }, 200)
//
//     } else if ($(this).scrollTop() <= 60) {
//         $('.header').css('opacity', '0')
//         $('.header').removeClass('header--fixed')
//         setTimeout(() => {
//             $('.header').css('opacity', '1').fadeIn(200)
//         }, 100)
//     }
// }));


$(window).on('load resize', function () {
    if (window.innerWidth < 720) {
        $('.cases-filter__items.filter').addClass('mobile-content')
        $('.cases-filter__items.filter').removeClass('desktop-content')
    } else {
        $('.cases-filter__items.filter').removeClass('mobile-content')
        $('.cases-filter__items.filter').addClass('desktop-content')
    }

    if ($('.banner').length) {
        $('.banner').css('height', `${window.innerHeight}px`)
        $('.banner').css('width', `${window.innerWidth}px`)
    }
})



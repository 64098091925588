import {
    MathUtils,
    PerspectiveCamera,
    PlaneGeometry, Points,
    PointsMaterial,
    Scene,
    TextureLoader,
    Vector3,
    WebGLRenderer,
    BufferGeometry,
    BufferAttribute,
    Clock,
} from "three/src/Three.js";
import {AdditiveBlending, Float32BufferAttribute, MeshBasicMaterial, PointLight, ShaderMaterial} from "three";


class Perlin {
    constructor() {
        let i;
        this.grad3 =
            [[1, 1, 0], [-1, 1, 0], [1, -1, 0], [-1, -1, 0],
                [1, 0, 1], [-1, 0, 1], [1, 0, -1], [-1, 0, -1],
                [0, 1, 1], [0, -1, 1], [0, 1, -1], [0, -1, -1]];
        this.p = [];
        for (i = 0; i < 256; i++) {
            this.p[i] = Math.floor(Math.random() * 256);
        }

        this.perm = [];
        for (i = 0; i < 512; i++) {
            this.perm[i] = this.p[i & 255];
        }
        this.simplex = [
            [0, 1, 2, 3], [0, 1, 3, 2], [0, 0, 0, 0], [0, 2, 3, 1], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [1, 2, 3, 0],
            [0, 2, 1, 3], [0, 0, 0, 0], [0, 3, 1, 2], [0, 3, 2, 1], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [1, 3, 2, 0],
            [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0],
            [1, 2, 0, 3], [0, 0, 0, 0], [1, 3, 0, 2], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [2, 3, 0, 1], [2, 3, 1, 0],
            [1, 0, 2, 3], [1, 0, 3, 2], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [2, 0, 3, 1], [0, 0, 0, 0], [2, 1, 3, 0],
            [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0],
            [2, 0, 1, 3], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [3, 0, 1, 2], [3, 0, 2, 1], [0, 0, 0, 0], [3, 1, 2, 0],
            [2, 1, 0, 3], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [3, 1, 0, 2], [0, 0, 0, 0], [3, 2, 0, 1], [3, 2, 1, 0]];
    }

    dot(g, x, y) {
        return g[0] * x + g[1] * y;
    }

    noise(xin, yin) {
        let n0, n1, n2;
        const F2 = 0.5 * (Math.sqrt(3.0) - 1.0);
        const s = (xin + yin) * F2;
        const i = Math.floor(xin + s);
        const j = Math.floor(yin + s);
        const G2 = (3.0 - Math.sqrt(3.0)) / 6.0;
        const t = (i + j) * G2;
        const X0 = i - t;
        const Y0 = j - t;
        const x0 = xin - X0;
        const y0 = yin - Y0;

        let i1, j1;
        if (x0 > y0) {
            i1 = 1;
            j1 = 0;
        } else {
            i1 = 0;
            j1 = 1;
        }
        const x1 = x0 - i1 + G2;
        const y1 = y0 - j1 + G2;
        const x2 = x0 - 1.0 + 2.0 * G2;
        const y2 = y0 - 1.0 + 2.0 * G2;
        const ii = i & 255;
        const jj = j & 255;
        const gi0 = this.perm[ii + this.perm[jj]] % 12;
        const gi1 = this.perm[ii + i1 + this.perm[jj + j1]] % 12;
        const gi2 = this.perm[ii + 1 + this.perm[jj + 1]] % 12;
        let t0 = 0.5 - x0 * x0 - y0 * y0;
        if (t0 < 0) n0 = 0.0;
        else {
            t0 *= t0;
            n0 = t0 * t0 * this.dot(this.grad3[gi0], x0, y0);
        }
        let t1 = 0.5 - x1 * x1 - y1 * y1;
        if (t1 < 0) n1 = 0.0;
        else {
            t1 *= t1;
            n1 = t1 * t1 * this.dot(this.grad3[gi1], x1, y1);
        }
        let t2 = 0.5 - x2 * x2 - y2 * y2;
        if (t2 < 0) n2 = 0.0;
        else {
            t2 *= t2;
            n2 = t2 * t2 * this.dot(this.grad3[gi2], x2, y2);
        }
        return 70.0 * (n0 + n1 + n2);
    }
}

let camera, scene, renderer;
let planeGeometry, planeMaterial, terrain, particlesMesh;
const target = document.querySelector('.webgl');
let width = window.innerWidth * 1.4;
if (window.innerWidth <= 768) {
    width = window.innerWidth;
}
const height = window.innerHeight;
const perlin = new Perlin();
let theta = 0;
const size = width > height ? width : height;

target?.classList.add('--originalColor')
const params = {
    blocks: 400,
    smoothing: 400,
    amplitude: 50,
    speed: 0.006,
    rotationX: -90,
    rotationZ: 0,
    planeSize: 0,
};

function init() {
    scene = new Scene();
    camera = new PerspectiveCamera(50, window.innerWidth / window.innerHeight, .5, 1000);
    scene.add(camera);
    renderer = new WebGLRenderer({antialias: true, canvas: target});
    renderer.setSize(width, height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.autoClear = false;
    renderer.setClearColor(0x000000, 0.0);
    renderer.sortObjects = false;
}

const numPointsSphere = 1800;
const pointsSphere = [];
const normalsSphere = [];

for (let i = 0; i < numPointsSphere; i++) {
    const theta = Math.random() * Math.PI;
    const phi = Math.random() * 2 * Math.PI;

    const x = Math.sin(theta) * Math.cos(phi);
    const y = Math.sin(theta) * Math.sin(phi);
    const z = Math.cos(theta);

    pointsSphere.push(x, y, z);
    const length = Math.sqrt(x * x + y * y + z * z);
    normalsSphere.push(x / length, y / length, z / length);
}
const geometrySphere = new BufferGeometry();
geometrySphere.setAttribute('position', new Float32BufferAttribute(pointsSphere, 3));
geometrySphere.setAttribute('normal', new Float32BufferAttribute(normalsSphere, 3));
if (geometrySphere.attributes.position !== undefined) {
    geometrySphere.computeBoundingSphere();
}
const particlePointsMaterial = new PointsMaterial({
    size:0.006,
    blending: AdditiveBlending
});
const particlesSphere = new Points(
    geometrySphere,particlePointsMaterial);


function createGeometry() {
    while (scene.children.length > 0) {
        scene.remove(scene.children[0]);
    }
    const textureLoader = new TextureLoader();
    const alpha = textureLoader.load(
        "img/water1.webp"
    );

    const particlesGeometry = new BufferGeometry;
    const particlesCnt = 100;

    const posArray = new Float32Array(particlesCnt * 3);
    for (let i = 0; i < particlesCnt * 3; i++) {
        posArray[i] = (Math.random() -0.9) * (Math.random() * 400) * 10
    }
    particlesGeometry.setAttribute('position', new BufferAttribute(posArray, 3));

    scene.add(particlesSphere);
    camera.lookAt(particlesSphere.position);
    const particlesMaterial = new PointsMaterial({
        size: 0.03,
    })

    const points = [];
    points.push(new Vector3(-5, 0, 0));
    points.push(new Vector3(0, 5, 0));
    points.push(new Vector3(5, 0, 0));

    planeGeometry = new PlaneGeometry(size, size, params.blocks, params.blocks)
    planeMaterial = new PointsMaterial({color: '#8e8bff', size: 0.0005, alphaMap: alpha});

    terrain = new Points(planeGeometry, planeMaterial);
    terrain.rotation.x = MathUtils.degToRad(params.rotationX);
    terrain.rotation.z = MathUtils.degToRad(params.rotationZ);
    terrain.position.y = -100;
    terrain.position.z = 140
    terrain.rotation.y = -500;
    terrain.rotation.x = 180;
    terrain.rotation.z = -120;
    if ((window.innerWidth > 900)&&(window.innerWidth < 3000)) {
        scene.add(terrain)
    } else{
        scene.remove(terrain);
    }

    particlesMesh = new Points(particlesGeometry, particlesMaterial);
    particlesMesh.position.y = 0;
    scene.add(particlesMesh)


}

const resizeFunction = () => {
    if (window.innerWidth > 2500) {
        camera.position.set(1.5, 1.5, 1.5);
    }
    if (window.innerWidth <= 1920) {
        camera.position.set(2, 2, 2);
    }
    if (window.innerWidth <= 1600) {
        camera.position.set(2.5, 2.5, 2.5);
    }
    if (window.innerWidth <= 1000) {
        camera.position.set(3.5, 3.5, 3.5);
    }
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix()
    renderer?.setSize(window.innerWidth, window.innerHeight)
    renderer?.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}

function refreshVertices(geom) {
        const vertices = geom.geometry.attributes.position.array;
        for (let i = 0; i <= vertices.length; i += 3) {
            vertices[i + 2] = params.amplitude * perlin.noise(
                ((geom.position.x + vertices[i]) / params.smoothing) + theta,
                ((geom.position.z + vertices[i + 1]) / params.smoothing) + theta
            );
        }
        geom.geometry.attributes.position.needsUpdate = true;
}

function refreshSphere(geometrySphere) {
    const vertices = geometrySphere.attributes.position.array;
    const normals = geometrySphere.attributes.normal.array;
    const time = clock.getElapsedTime();
    for (let i = 0; i < vertices.length; i += 2) {
        const x = vertices[i];
        const y = vertices[i + 1];
        const z = vertices[i + 2];

        const normalX = normals[i]  + vertices[i] ;
        const normalY = normals[i + 1]  + vertices[i + 1] ;
        const normalZ = normals[i + 2] + vertices[i + 2];
        let amplitude = 0.06;
        const new_x = x + normalX * amplitude/2 ;
        const new_y = y + normalY * amplitude/2;
        const new_z = z + normalZ * amplitude/2;
        const distance = Math.cbrt(new_x * x*x + new_y * y*y + new_z * z*z);

        const wave = Math.cos(distance * Math.PI * perlin.noise(normals[i], normals[i + 1] ) + time *2);

        vertices[i + 3] = -amplitude * wave + normals[i];
        vertices[i + 1] = amplitude * wave + normals[i + 1];
        vertices[i + 2] = amplitude  * wave  + normals[i + 2];
    }
    geometrySphere.attributes.position.needsUpdate = true;
    geometrySphere.attributes.normal.needsUpdate = true;
}


const clock = new Clock()
let mouseX = 0;
let mouseY = 0;
let initAnim = true;

function animate() {
    if (!initAnim) return;
    renderer.shadowMapEnabled = true
    const elapsedTime = clock.getElapsedTime();
    theta += params.speed;
    window.requestAnimationFrame(animate);

    if ((window.innerWidth > 900)&&(window.innerWidth < 3000)) {
        refreshVertices(terrain);
    }
    refreshSphere(geometrySphere)
    particlesMesh.rotation.y = -0.01 * elapsedTime
    if (mouseX > 0) {
        particlesMesh.rotation.x = -mouseY * (elapsedTime * 0.00008)
        particlesMesh.rotation.y = mouseX * (elapsedTime * 0.00008)
    }

    particlesSphere.rotation.x = 0.0001 * elapsedTime;
    particlesSphere.rotation.y = 0.0001 * elapsedTime;
    particlesSphere.rotation.z = -0.0001 * elapsedTime;
    renderer.render(scene, camera);
}

document.addEventListener("DOMContentLoaded", function () {
    if ($('.webgl').length) {
        init();
        resizeFunction()
        createGeometry();
        window.addEventListener('load', resizeFunction)
    }

    const iObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                initAnim = true;
                animate()
            } else {
                initAnim = false;
            }
        });
    });

    const box = document.querySelectorAll(".banner");
    box.forEach((el) => {
        iObserver.observe(el);
    });
})


import Chart from 'chart.js/auto';

document.addEventListener('DOMContentLoaded', function () {
    let animationCompleted = false;

    function drawChart( ctx,animationCompleted) {

        if (ctx) {

            const ctxGradient = ctx.getContext('2d');
            const gradient = ctxGradient.createLinearGradient(0, 0, 0, 350);
            gradient.addColorStop(0, '#A1C8FF');
            gradient.addColorStop(.7, '#7232F2');
            gradient.addColorStop(1, '#403CE9');
            ctxGradient.shadowColor = '#7232F2';

            const datapoints = [800, 200];
            const data = {
                labels: ['1', '2'],
                datasets: [{
                    label: 'Конверсия',
                    data: datapoints,
                    backgroundColor: [
                        gradient,
                        '#D9DDE0'
                    ],
                    borderColor: [
                        gradient,
                        '#D9DDE0'
                    ],
                    borderWidth: 1,
                    cutout: '90%',
                    borderRadius: 30,
                    radius: 85
                }]
            }

            let count = 0;

            function firstUpdate() {
                setTimeout(() => {
                    myChart.update();
                }, 100)
            }

            function secondUpdate() {
                setInterval(() => {
                    myChart.update();
                }, 1000)
            }

            function updateCounter(animationCompleted) {
                if (window.scrollY > 600 && count < 10.4) {
                    count = count + 0.1;
                    firstUpdate();
                } else if (count > 10.4 && count < 12 && animationCompleted) {
                    count = count + 0.001;
                    secondUpdate();
                } else if (count > 12) {
                    myChart.update('none');
                    myChart.options.animation=false
                }
            }

            const counter = {
                id: 'counter',
                beforeDraw(chart, arg, options) {
                    const {ctx, chartArea: {top, right, bottom, left, width, height}} = chart;
                    ctx.save();
                    ctx.fillStyle = options.fontcolor;
                    ctx.font = options.fontsize + "px" + " " + options.fontFamily;
                    ctx.fontWeight = options.fontWeight;
                    ctx.textAlign = 'center';
                    ctx.fillText(`${count.toFixed(1)}%`, width / 2, top + ((height / 2) + (options.fontsize * 0.24)))
                },
            }

            const differencePoint = '🛆';
            const text1 = '+2.6% ';
            const text2 = 'в мес'

            const counterDifference = {
                id: 'counterDifference',
                beforeDraw(chart, arg, options) {
                    const {ctx, chartArea: {top, right, bottom, left, width, height}} = chart;
                    ctx.save();
                    ctx.fillStyle = options.fontcolor;
                    ctx.strokeStyle = options.fontcolor;
                    ctx.font = options.fontsize + "px" + " " + options.fontFamily;
                    ctx.textAlign = 'center';
                    ctx.fillText(`${differencePoint} ${text1} ${text2}`, width / 2, top + 35 + (height / 2))
                }
            }

            let delayed;

            const config = {
                type: 'doughnut',
                data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    rotation: 160,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Конверсия',
                            color: '#111',
                            font: {
                                weight: 700,
                                size: 18,
                                family: 'Inter'
                            },
                            position: 'top',
                            align: 'start',
                            padding: {
                                top: 20,
                                bottom: 10,
                            },
                        },
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        },
                        counter: {
                            fontcolor: '#111111',
                            fontsize: 44,
                            fontFamily: 'SF',
                            fontWeight: 900
                        },
                        counterDifference: {
                            fontcolor: '#39BF9F',
                            fontsize: 15,
                            fontFamily: 'Inter',
                        },
                        deferred: {
                            yOffset: '50%',
                        }
                    },
                    animation: {
                        loop: true,
                        duration: 4000,
                        onComplete: () => {
                            delayed = true;
                            animationCompleted=true;
                        },
                        delay: (context) => {
                            let delay = 0;
                            if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 300 + context.datasetIndex * 100;
                            }
                            return delay;
                        },
                        onProgress: () => {
                            updateCounter(animationCompleted);
                        },
                    },
                },
                plugins: [counter, counterDifference],
            }

            const myChart = new Chart(document.getElementById('myChart'),
                config
            )

        }
    }

    const chartContainer = document.querySelector('.graph__block');
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
               let animationCompleted=true
                drawChart( document.getElementById('myChart'),animationCompleted)
                observer.unobserve(chartContainer);
            }
        });
    }, { threshold: 0 });

    observer.observe(chartContainer);
})




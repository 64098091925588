import {easingEffects} from 'chart.js/helpers'


const totalDuration = 1600;
let easing = easingEffects.linear;
export const createProps = (ctx, data) => {
    const duration = (ctx) => easing(ctx.index / data.length) * totalDuration / data.length * 0.005;
    const delay = (ctx) => easing(ctx.index / data.length) * totalDuration + 0.5;
    const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
    return {duration, delay, previousY}
}

const ctx = document.getElementById('lineChart');
export const data = [];
export let positions = [60, 90, 120, 130, 130, 120, 110, 90, 90, 150, 190, 220, 220, 200, 180, 160, 160, 190, 280, 310, 310, 280, 210, 200, 200, 220, 240, 260, 260, 260, 280, 290, 300, 320, 340, 360]

getData(positions)

export const props1 = createProps(ctx, data)

export const animation = (props) => {
    const x = {
        type: 'number',
        easing: 'linear',
        duration: props.duration,
        from: NaN, // the point is initially skipped
        delay(ctx) {
            if (ctx.type !== 'data' || ctx.xStarted) {
                return 0;
            }
            ctx.xStarted = true;
            return props.delay(ctx);
        }
    };
    const y = {
        type: 'number',
        easing: 'linear',
        duration: () => props.duration,
        from: () => props.previousY,
        delay(ctx) {
            if (ctx.type !== 'data' || ctx.yStarted) {
                return 0;
            }
            ctx.yStarted = true;
            return props.delay(ctx);
        }
    }
    return {x, y}
}

export function getGradient(ctx) {
    if (ctx) {
        const gradient = ctx?.getContext('2d').createLinearGradient(0, 0, 0, 350);
        gradient?.addColorStop(0, '#A1C8FF');
        gradient?.addColorStop(.7, '#7232F2');
        gradient?.addColorStop(1, '#403CE9');
        ctx.getContext('2d').shadowColor = '#7232F2';

        const backgradient = ctx.getContext('2d').createLinearGradient(0, 0, 0, 350);
        backgradient.addColorStop(0, 'rgba(112,186,255,0.32)');
        backgradient.addColorStop(1, 'rgba(64,60,233,0.32)');

        return {gradient, backgradient}
    }
}

function getData(positions) {
    for (let i = 0; i < positions.length; i++) {
        data.push({x: i, y: positions[i]});
    }
    return data
}



